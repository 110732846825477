import React, { useEffect, useState  } from 'react'
import { graphql, Link } from "gatsby"
import { SliceZone } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'
import {
  scroller
} from "react-scroll"
import styled from "@emotion/styled"
import { motion } from "framer-motion"
import useOnclickOutside from "react-cool-onclickoutside"
import { LinkSimple } from "phosphor-react"

import { components } from '../slices'
import Layout from '../components/layout'

const Header = styled.header`
position: fixed;
left: 0;
top: 0;
background-color: rgba(0,0,0,1);
height: 5vh;
width: 90%;
padding: 0 5%;
z-index: 5;
@media (max-width: 599px) {
  height: auto;
}
h1, h2 {
    display: inline-block;
    color: white;
    line-height: 3vh;
    margin-top: 1vh;
    margin-bottom: 1vh;
    text-transform: uppercase;
    font-family: "Trade Gothic Bold";
    vertical-align: middle;
}
h1 {
    font-size: 1.25rem;
    span {
        font-family: "Trade Gothic Light";
        color: rgba(255,255,255,0.5);
    }
    a {
      color: #fff;
      transition: all 0.3s ease;
      &:hover {
        color: rgb(241,90,36);
      }
    }
    @media (max-width: 599px) {
      font-size: 1.5rem;
      margin-top: 2vh;
      margin-bottom: .5vh;
    }
}
h2 {
    font-family: "DIN Bold";
    margin-left: 1vw;
    padding-left: 1vw;
    padding-top: 2px;
    border-left: solid 1px rgba(255,255,255,0.6);
    font-size: .9375rem;
    color: rgb(241,90,36);
    @media (max-width: 599px) {
      padding: 0;
      margin: 0;
      border: 0;
      line-height: 1em;
      margin-bottom: 2vh;
    }
}
`
const Sidebar = styled.aside`
    width: 30%;
    padding-left: 5%;
    float: right;
    background: #fff;
    @media (max-width: 599px) {
      float: none;
      width: inherit;
      padding-left: 0;
    }
`
const Section = styled.section`
padding: 6vh 4vw 4vh 4vw;
box-sizing: border-box;
background-color: rgb(255,255,255);
margin-top: 12.5vh;
position: relative;
&:nth-of-type(1) {
    margin-top: 5vh;
}
@media (max-width: 599px) {
  margin-left: 10vw;
  margin-right: 4vw;
}
a.button {
  text-decoration: none;
  color: rgb(0,0,0);
  transition: all 0.3s ease;
  display: block;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 0.75vw 1vw 0.5vw 1vw;
  text-transform: uppercase;
  text-shadow: 1px 1px 3px rgb(0 0 0 / 20%);
  font-family: "DIN Bold";
  font-size: .875rem;
  letter-spacing: 2px;
  border-radius: 5px;
  margin-top: 1vh;
  background-image: -webkit-repeating-linear-gradient(135deg,rgba(241,90,36,.8),rgba(241,90,36,.8) 30px,rgba(241,90,36,1) 30px,rgba(241,90,36,1) 60px);
  background-image: repeating-linear-gradient(135deg,rgba(241,90,36,.8),rgba(241,90,36,.8) 30px,rgba(241,90,36,1) 30px,rgba(241,90,36,1) 60px);
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  opacity: 0.75;
  color: rgba(255,255,255,1);
  border: none;
  &:hover {
    box-shadow: 0 0 10px rgb(36 168 224 / 80%);
    opacity: 1;
  }@media (max-width: 599px) {
    padding: 12px 12px 8px 12px;
  }
  
}
header {
  position: absolute;
  right: 4vw;
  top: 4vh;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: .9125rem;
  @media (max-width: 599px) {
    right: inherit;
    top: 10vh;
    width: 92%;
    box-sizing: border-box;
    text-align: center;
  }
  
}
h2 {
  font-size: 2.25rem;
  line-height: 1em;
  font-family: "Trade Gothic Bold";
  color: rgb(0,0,0);
  text-transform: uppercase;
  margin-bottom: .75em;
  letter-spacing: -1px;
}
h3 {
  @media (max-width: 599px) {
    font-size: 1.5rem;
  }
}
h3.week {
  text-align: center;
  font-size: 1.875rem;
  font-family: "Chaparral Light";
  color: #fff;
  left: 50%;
  transform: translateX(-50%);
  padding: 2vh 4vh 2vh 4vh;
  margin-bottom: 3vh;
  border-top: none;
  background-color: rgba(0,0,0,0.4);
  position: absolute;
  top: 0;
  z-index: 3;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
  @media (max-width: 599px) {
    left: 0;
    width: 100%;
    box-sizing: border-box;
    transform: none;
    font-size: 1.66rem;
  }
}
h4 {
  font-family: "DIN Bold";
  color: rgb(0,0,0);
  font-size: 1.125rem;
  line-height: 1.75em;
  transition: all 0.3s ease;
  border-top: solid 1px rgba(0,0,0,0.1);
  padding-top: 2.5vh;
  margin-top: 6vh;
}
h5 {
  font-size: .9375rem;
  line-height: 1.75em;
  strong {
    font-family: "DIN Bold";
  }
}
h6 {
  font-family: "Trade Gothic Light";
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: .8125rem;
    line-height: 1.75em;
    color: rgb(0,0,0);
    margin-botom: .5vh;
    strong {
      font-family: 'DIN Bold';
      color: rgb(0,0,0);
    }
}
article {
  margin-top: 2vh;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2vw;
  @media (max-width: 599px) {
    display: block;
  }
}
p {
  margin-bottom: 1.5em;
  color: rgba(0,0,0,.7);
  font-size: 1.125rem;
  line-height: 1.375em;
  font-family: "Chaparral Regular";
  strong {
    font-family: 'DIN Bold';
    color: rgb(0,0,0);
    font-size: .9em;
    transition: all 1s ease;
  }
}
div.caption {
  color: rgba(0,0,0,0.33);
  margin-top: 12px;
  font-size: .8125rem;
  text-align: center;
  margin-bottom: 1.5vh;
}
ul {
  margin-bottom: 1.5em;
  li {
    padding-bottom: 0.75vh;
    margin-bottom: 1vh;
    font-size: .9375rem;
    line-height: 1.375em;
    border-bottom: solid 1px rgba(0,0,0,0.2);
    text-align: left;
    width: 90%;
    transition: all 1s ease;
    &:nth-of-type(1) {
      padding-top: .75vh;
      border-top: solid 1px rgba(0,0,0,0.2);
    }
    em {
      border-bottom: dashed 1px rgba(0,0,0,0.6);
    }
    strong {
      font-family: 'DIN Bold';
    }
  }
}
.card.lecture {
  border-top: solid 1px rgba(0,0,0,0.1);
  padding-top: 2.5vh;
  margin-top: 6vh;
  h4 {
    padding: 0;
    margin: 0;
    border: 0;
    font-size: 1rem;
  }
  p {
    border-bottom: dashed 1px rgb(0,0,0);
    padding-bottom: 20px;
    margin-bottom: 20px;;
  }
  > div {
    box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
    padding: 20px;
  }
  .thumbnail {
    a {
      display: grid;
      width: 100%;
      grid-template-columns: 3fr 5fr;
      column-gap: 2vw;
      border-bottom: 0;
      &:hover {
        color: rgb(36,168,224);
      }
      @media (max-width: 599px) {
        column-gap: 4vw;
      }
    }
  }
}

`
const Footernav = styled.footer`
pointer-events: all;
.simplebar-scrollbar::before {
  background-color: rgba(0,0,0,0.33);
}
&[data-active='true'] {
  background-color: rgba(255,255,255,.6);
}
nav {
  overflow: hidden;
}
button {
    position: fixed;
    right: 2.5vw;
    bottom: 5vh;
    background: transparent;
    padding: 0;
    border: 0;
    color: #000;
    text-transform: uppercase;
    font-family: 'courier';
    font-size: .8125rem;
    letter-spacing: 3px;
    text-align: left;
    z-index: 10;
    transition: color 0.3s ease, background-color 0.3s ease;
    &:hover {
        cursor: pointer;
        color: rgba(0,0,0,0.75);
    }
    &[data-ison='true'] {
      position: fixed;
      color: rgb(0,0,0);
      color: #fff;
      font-weight: bold;
    }
    @media (max-width: 599px) {
      bottom: 2.5vh;
    }
}
> div {
    position: fixed;
    z-index: 7;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,0.90);
    transition: visibility 0 linear;
    transition-delay: .3s;
    transition-property: visibility;
    > section {
        padding: 5vh 4vw 3vh 5vw;
        width: 100vw;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-column-gap: 1vw;
        overflow: hidden;
      @media (max-width: 599px) {
        display: block;
        overflow-y: scroll;
        height: 65vh;
        > div {
          margin-bottom: 2.5vh;
          &:last-of-type {
            margin-bottom: 10vh;
          }
        }
      }
    }
    @media (max-width: 599px) {
      height: 50vh;
    }
}
li {
    list-style: none;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: 600;
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: red;
    padding-bottom: 1vh;
    margin-bottom: 1vh;
    transition: all 0.3s ease;
    border-bottom: solid 1px transparent;
    a {
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      font-size: .875rem;
      line-height: 1.1em;
      font-weight: bold;
      font-family: 'courier';
      border-bottom: solid 1px rgb(255,255,255,0.33);
      transition: all 0.3s ease;
      &:hover {
          border-bottom-color: transparent;
          color: rgb(36,168,224);
      }
    }
}
`
const _ = require("lodash")

const ClassTemplate = ({data}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const handleBtnClick = () => setOpenMenu(!openMenu);
  const closeMenu = () => setOpenMenu(false);
  const ref = useOnclickOutside(() => closeMenu());

  useEffect(() => {
      scroller.scrollTo("current", {
        duration: 1000,
        delay: 0.5,
        smooth: true,
        offset: -95
      });
    })
    if (!data) return null
    const doc = data.prismicClass.data
    const sectionNum = doc.classes.length
    let n = 1

    return (
        <Layout>
        <Header>
            <h1><Link to='/'>{doc.class_name.text} <span>{doc.semester.text}{doc.year.text}</span></Link></h1>
            <h2>{doc.teacher.text}</h2>
        </Header>
        <Section>
          <h2>{doc.syllabus.document.data.title.text}</h2>
        <Sidebar>
            <PrismicRichText field={doc.syllabus.document.data.contact_info.richText} />
        </Sidebar>
        <PrismicRichText field={doc.syllabus.document.data.course_description.richText} />
        <PrismicRichText field={doc.syllabus.document.data.learning_outcomes.richText} />
        <SliceZone slices={doc.syllabus.document.data.body} components={components} />
        </Section>
        {doc.classes &&
        <>
        {doc.classes.map(item => {
          const secNum = n++
          return (
          <>
          {item.class_meetings.document &&
          <Section key={item.class_meetings.document.id} id={secNum === sectionNum ? 'current' : `${_.kebabCase(item.class_meetings.document.data.title.text)}`}>
            <header>{item.class_meetings.document.data.topic.text}</header>
            <h3 className="week">{item.class_meetings.document.data.title.text} - {item.class_meetings.document.data.class_date}</h3>
            <article>
            <SliceZone slices={item.class_meetings.document.data.body} components={components} />
            </article>
          </Section>
          }
          </>
          )
        })}
        </>
        }
        <Footernav ref={ref}>
        <button onClick={handleBtnClick} data-ison={openMenu} ><LinkSimple size={40} weight="bold" /></button>
        {openMenu && (
            <motion.div
            initial={{ opacity: 0, y: "10vh" }}
            animate={{
              y: "0vh",
              opacity: 1
            }}
            transition={{type: "spring", duration: 0.5}}
            >
          <section>
          <PrismicRichText field={doc.frequent_links.richText} />
          </section>
        </motion.div>
        )}
        </Footernav>
        </Layout>
    )
}

export default ClassTemplate

export const pageQuery = graphql`
query ($id: String) {
    prismicClass(id: {eq: $id}) {
        data {
          class_name {
            text
          }
          semester {
            text
          }
          teacher {
            text
          }
          year {
            text
          }
          frequent_links {
            richText
          }    
          syllabus {
            document {
              ... on PrismicSyllabus {
                id
                data {
                  body {
                    ... on PrismicSlice {
                        slice_type
                    }
                    ...SyllabusDataBody1Column 
                    ...SyllabusDataBody3Columns
                  }
                  course_description {
                    richText
                  }
                  learning_outcomes {
                    richText
                  }
                  contact_info {
                    richText
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
          classes {
            class_meetings {
              document {
                ... on PrismicClassMeeting {
                  id
                  data {
                    class_date(formatString: "MM-DD-YY")
                    title {
                      text
                    }
                    topic {
                      text
                    }
                    body {
                        ... on PrismicSlice {
                            slice_type
                        }
                        ...ClassMeetingDataBodyCharette
                        ...ClassMeetingDataBodyReading
                        ...ClassMeetingDataBodyResource
                        ...ClassMeetingDataBodyLecture
                        ...ClassMeetingDataBodyButton
                    }
                  }
                }
              }
            }
          }
        }
      }
}
`
